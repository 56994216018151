<template>
    <div class='grid crud-demo'>
        <div class='col-12'>
            <div class='card'>
                <Toast />
                <Toolbar class='mb-4'>
                    <template v-slot:end>
                        <div class='my-2'>
                            <Button label='Adicionar' icon='pi pi-plus' class='p-button-success mr-2'
                                    @click='openNew' />
                            <Button label='Deletar' icon='pi pi-trash' class='p-button-danger'
                                    @click='confirmDeleteSelected'
                                    :disabled='!selectedBanks || !selectedBanks.length' />
                        </div>
                    </template>
                </Toolbar>

                <DataTable ref='dt' :value='banks' v-model:selection='selectedBanks' dataKey='id'
                           :paginator='true' :rows='10' :filters='filters'
                           paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                           :rowsPerPageOptions='[5,10,25]'
                           currentPageReportTemplate='Mostrando {first} ~ {last} total {totalRecords} contas de banco'
                           responsiveLayout='scroll'>
                    <template #header>
                        <div class='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
                            <h5 class='m-0'>Contas de banco</h5>
                            <span class='block mt-2 md:mt-0 p-input-icon-left'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Pesquisar...' />
                            </span>
                        </div>
                    </template>

                    <Column selectionMode='multiple' headerStyle='width: 3rem'></Column>
                    <Column headerStyle='width: 20rem' field='name' header='Nome' :sortable='true'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Nome</span>
                            {{ slotProps.data.name }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 10rem' field='agency' header='Agência' :sortable='true'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Agência</span>
                            {{ slotProps.data.agency }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 10rem' field='account' header='Conta' :sortable='true'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Conta</span>
                            {{ slotProps.data.account }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 10rem' field='initialBalance' header='Saldo inicial' :sortable='true'>
                        <template #body='slotProps'>
                            <span class='p-column-title'>Balanço inicial</span>
                            {{ formatCurrency(slotProps.data.initialBalance) }}
                        </template>
                    </Column>
                    <!--TODO implement it after transactions -->
                    <Column headerStyle='width: 10rem' field='balance' header='Saldo' :sortable='true'>
                        <template>
                            <span class='p-column-title'>Balanço Atual</span>
                            {{ formatCurrency(0) }}
                        </template>
                    </Column>
                    <Column headerStyle='width: 8rem'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-pencil' class='p-button-rounded p-button-success mr-2'
                                    @click='editBank(slotProps.data)' />
                            <Button icon='pi pi-trash' class='p-button-rounded p-button-warning'
                                    @click='confirmDeleteBank(slotProps.data)' />
                        </template>
                    </Column>
                </DataTable>

                <Dialog v-model:visible='bankDialog' :style="{width: '450px'}" header='Criar / Editar conta de banco'
                        :modal='true' class='p-fluid'>
                    <div class='field'>
                        <label for='name'>Nome*</label>
                        <InputText :disabled='isEdit' id='name' v-model.trim='bank.name' required='true' autofocus
                                   :class="{'p-invalid': v$.bank.name.$invalid && submitted}" />
                        <small
                            v-if='(v$.bank.name.$invalid && submitted) || v$.bank.name.$pending.$response'
                            class='p-error'>{{ v$.bank.name.required.$message.replace('Value', 'Nome') }}</small>
                    </div>
                    <div class='field'>
                        <label for='name'>Agência*</label>
                        <InputText :disabled='isEdit' id='agency' v-model.trim='bank.agency' required='true' autofocus
                                   :class="{'p-invalid': v$.bank.agency.$invalid && submitted}" />
                        <small
                            v-if='(v$.bank.agency.$invalid && submitted) || v$.bank.agency.$pending.$response'
                            class='p-error'>{{ v$.bank.agency.required.$message.replace('Value', 'Agência') }}</small>
                    </div>
                    <div class='field'>
                        <label for='name'>Conta*</label>
                        <InputText :disabled='isEdit' id='name' v-model.trim='bank.account' required='true' autofocus
                                   :class="{'p-invalid': v$.bank.account.$invalid && submitted}" />
                        <small
                            v-if='(v$.bank.account.$invalid && submitted) || v$.bank.account.$pending.$response'
                            class='p-error'>{{ v$.bank.account.required.$message.replace('Value', 'Conta') }}</small>
                    </div>
                    <div class='field'>
                        <label for='name'>Balanço inicial*</label>
                        <InputNumber mode='currency' currency='JPY' locale='ja-JP' id='name'
                                     v-model.trim='bank.initialBalance' required='true' autofocus
                                     :class="{'p-invalid': v$.bank.initialBalance.$invalid && submitted}" />
                        <small
                            v-if='(v$.bank.initialBalance.$invalid && submitted) || v$.bank.initialBalance.$pending.$response'
                            class='p-error'>{{ v$.bank.initialBalance.required.$message.replace('Value', 'Conta')
                            }}</small>
                    </div>
                    <template #footer>
                        <Button label='Cancelar' icon='pi pi-times' class='p-button-text' @click='hideDialog' />
                        <Button label='Salvar' icon='pi pi-check' class='p-button-text'
                                @click='saveBank(!v$.$invalid)' />
                    </template>
                </Dialog>

                <Dialog v-model:visible='deleteBankDialog' :style="{width: '450px'}" header='Confirmar'
                        :modal='true'>
                    <div class='flex align-items-center justify-content-center'>
                        <i class='pi pi-exclamation-triangle mr-3' style='font-size: 2rem' />
                        <span v-if='bank'>Tem certeza que deseja excluir a categoria <b>{{ bank.name
                            }}</b>?</span>
                    </div>
                    <template #footer>
                        <Button label='Não' icon='pi pi-times' class='p-button-text'
                                @click='deleteBankDialog = false' />
                        <Button label='Sim' icon='pi pi-check' class='p-button-text' @click='deleteBank' />
                    </template>
                </Dialog>

                <Dialog v-model:visible='deleteBanksDialog' :style="{width: '450px'}" header='Confirmar'
                        :modal='true'>
                    <div class='flex align-items-center justify-content-center'>
                        <i class='pi pi-exclamation-triangle mr-3' style='font-size: 2rem' />
                        <span v-if='bank'>Tem certeza que deseja excluir as categorias selecionadas ?</span>
                    </div>
                    <template #footer>
                        <Button label='Não' icon='pi pi-times' class='p-button-text'
                                @click='deleteBanksDialog = false' />
                        <Button label='Sim' icon='pi pi-check' class='p-button-text'
                                @click='deleteSelectedBanks' />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import BankService from '@/services/bank.service';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            isEdit: false,
            banks: null,
            bankDialog: false,
            deleteBankDialog: false,
            deleteBanksDialog: false,
            bank: {},
            selectedBanks: null,
            filters: {},
            submitted: false,
        };
    },
    validations: {
        bank: {
            name: {
                required,
            },
            agency: {
                required,
            },
            account: {
                required,
            },
            initialBalance: {
                required,
            },
        },
    },
    bankService: null,
    created() {
        this.initFilters();
    },
    mounted() {
        this.fetchBanks();
    },
    methods: {
        //TODO make it utils.
        formatCurrency(value) {
            //TODO use database to set this.
            if (value) return value.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' });
        },
        openNew() {
            this.isEdit = false;
            this.bank = {};
            this.submitted = false;
            this.bankDialog = true;
        },
        hideDialog() {
            this.bankDialog = false;
            this.submitted = false;
        },
        async saveBank(isFormValid) {
            if (!isFormValid) {
                this.submitted = true;
                return;
            }

            if (this.bank.name.trim()) {
                if (this.bank.id) {
                    try {
                        let resp = await BankService.update(this.bank);
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: resp.data.message,
                            life: 3000,
                        });
                    } catch (e) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: e.response.data.message,
                            life: 5000,
                        });
                    }
                } else {
                    try {
                        let resp = await BankService.create(this.bank);
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Success',
                            detail: resp.data.message,
                            life: 3000,
                        });
                    } catch (e) {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: e.response.data.message,
                            life: 5000,
                        });
                    }
                }
            }
            await this.fetchBanks();
            this.bankDialog = false;
            this.bank = {};
        },
        editBank(bank) {
            this.isEdit = true;
            this.bank = { ...bank };
            this.bankDialog = true;
        },
        confirmDeleteBank(bank) {
            this.bank = bank;
            this.deleteBankDialog = true;
        },
        async deleteBank() {
            try {
                let resp = await BankService.delete(this.bank.id);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: resp.data.message,
                    life: 3000,
                });
            } catch (e) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: e.response.data.message,
                    life: 5000,
                });
            }
            await this.fetchBanks();
            this.deleteBankDialog = false;
            this.bank = {};
        },
        confirmDeleteSelected() {
            this.deleteBanksDialog = true;
        },
        async deleteSelectedBanks() {
            try {
                BankService.deleteMany(this.selectedBanks).then(resp => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Success',
                        detail: resp.data.message,
                        life: 3000,
                    });
                });
            } catch (e) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: e.response.data.message,
                    life: 5000,
                });
            }
            await this.fetchBanks();
            this.deleteBanksDialog = false;
            this.selectedBanks = null;
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        async fetchBanks() {
            let resp = await BankService.find();
            this.banks = resp.data;
        },
    },
};
</script>

<style scoped lang='scss'>
@import '../../assets/demo/styles/badges.scss';
</style>
