import api from './index';

class BankService {
    async find() {
        return api.get(`/banks`);
    }

    async create(data) {
        return api.post(`/banks`, data);
    }

    async update(data) {
        return api.put(`/banks`, data);
    }

    async delete(id) {
        return api.delete(`/banks/${id}`);
    }

    async deleteMany(selectedCategories) {
        return api.post(`/banks/deleteMany`, selectedCategories);
    }
}

export default new BankService();